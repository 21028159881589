@media (max-width: 1050px) {
  .main,
  .main_open {
    margin-left: 0px;
    width: 100%;
    height: 100%;
    padding: 12px;

    scrollbar-width: none;
    -ms-overflow-style: none;
  }

  .main::-webkit-scrollbar {
    display: none;
  }

  .main_box {
    display: flex;
    flex-flow: column;
    height: calc(100vh - 0px);
  }

  .login_container {
    width: 90%;
    height: 380px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  .btn {
    width: 100%;
    margin-bottom: 15px;
  }

  .cases-photo {
    width: 100%;
  }

  .company-name {
    flex-direction: column;
  }

  .company-name-logo {
    width: 100%;
    margin-bottom: 10px;
  }

  .show_protocol_meter {
    height: 55px;
    align-items: start;
    flex-direction: column;
  }

  .index_search {
    width: 100%;
  }

  .date {
    flex-direction: column;
  }

  .indications_box {
    flex-direction: column;
    margin-bottom: 5px;
  }

  .indications_box-gas {
    width: 100%;
  }

  .accepted_box p {
    color: #132532;
    font-weight: 700;
  }

  .customer {
    flex-direction: column;
  }

  .customer_name,
  .customer_address {
    width: 100%;
  }

  .customer_name > div > span {
    font-weight: 600;
  }
  .customer_name {
    align-items: flex-start !important;
  }

  .mechanical_characteristics_box {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .mechanical_characteristics_box_min_max {
    display: flex;
    flex-direction: column;
  }

  .mechanical_characteristics {
    width: 100%;
    margin-bottom: 10px;
  }

  .mechanical_characteristics_max {
    width: 100%;
    margin: 0 0 10px 0;
  }

  .meter {
    margin-bottom: 19px;
  }

  .flexContainerWithGap,
  .flexContainer {
    flex-direction: column;
  }

  .header {
    position: absolute;
  }

  .header .profile {
    right: 0;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .header .profile .profile_user p {
    z-index: 2;
  }

  .header img {
    display: none;
  }

  .edit-password-background {
    width: 95%;
  }

  .edit-password-background {
    width: 100%;
    padding: 50px 10px;
  }

  .device,
  .cases_kits {
    flex-direction: column;
  }

  .device_name,
  .device_number,
  .kits_type,
  .kits_sign,
  .kits_sign {
    width: 100%;
    margin: 0;
  }

  .device_sign {
    display: none;
  }

  .image-container,
  .btn_container {
    flex-direction: column;
  }

  .image-item {
    width: 100%;
  }

  .img_uploading {
    object-fit: contain;
    width: 100%;
    height: 250px;
  }

  .login_container {
    width: 90%;
    height: 380px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    border-radius: 20px;
    background-color: #ffffff;
  }

  .items-per-page {
    display: none;
  }

  .header_box {
    flex-direction: column;
    gap: 12px;
  }

  .company-name {
    flex-direction: column;
  }

  .company-name-logo {
    width: 100%;
    margin-bottom: 10px;
  }

  .header_index_box,
  .index_add_status_company {
    flex-direction: column;
  }

  .flexContainerWithGap,
  .flexContainer {
    width: 100%;
    flex-direction: column;
  }

  .width32Percent,
  .width24Point5Percent,
  .width67Percent,
  .width50Percent {
    width: 100%;
  }

  .accordion {
    flex-direction: column;
  }

  .selector {
    width: 100%;
  }

  .header {
    position: absolute;
  }

  .header .profile {
    right: 0;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  .header .profile .profile_user p {
    z-index: 2;
  }

  .header img {
    display: none;
  }

  .flexContainerWithGap {
    margin: 10px 0;
  }

  .indications {
    margin-top: 14px;
  }

  .indications_box-gas {
    width: 100% !important;
  }

  .edit-password-box {
    width: 100%;
    margin: 20px auto 0;
  }

  .btn--container {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }

  .img--container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .img--box {
    object-fit: cover;
    width: 87%;
    height: 87%;
    border-radius: 12px;
  }

  .img--zoom {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 90%;
    height: 60%;
    overflow: auto;
    object-fit: contain;
    background-color: white;
    border-radius: 12px;
  }

  .image-upload-container {
    display: block;
    position: relative;
    width: 100%;
    height: 210px;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='18' ry='18' stroke='gray' stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: 18px;
    overflow: hidden;
    margin-top: 18px;
  }

  .btn--box {
    display: flex;
    justify-content: center;
    gap: 14px;
  }

  // ----------------------------------------------------------------
  // форма протокола - на момент 16.05 только для воды
  // ----------------------------------------------------------------

  .meter {
    margin: 0 !important;
  }
  .M__TypeMethod__container {
    flex-flow: column !important;
    align-items: flex-start;
  }
  .M__TypeMethod__row {
    width: 100% !important;
  }
  .M__Customer__title {
    margin-bottom: 20px;
  }
  .M__Customer__container__row {
    width: 100% !important;
  }
  .M__Customer__container__rowEnd {
    margin-top: 10px;
  }
}
